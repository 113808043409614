import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
    return (
        <>
            <header>
                <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
                    <div className="container-fluid">
                        {/* Logo on the left */}
                        <Link className="navbar-brand" to="/">
                            <img src="/assets/Images/Property/logo.png" alt="Logo" style={{ height: '70px' }} />
                        </Link>
                        
                        {/* Toggle button for mobile view */}
                        <button 
                            className="navbar-toggler" 
                            type="button" 
                            data-bs-toggle="collapse" 
                            data-bs-target="#navbarCollapse" 
                            aria-controls="navbarCollapse" 
                            aria-expanded="false" 
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        {/* Navbar links */}
                        <div className="collapse navbar-collapse justify-content-end" id="navbarCollapse">
                            <ul className="navbar-nav mb-2 mb-md-0">
                                <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/buy">Buy</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/rent">Rent</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/projects">Projects</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/agents">Agents</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link 
                                        className="nav-link dropdown-toggle" 
                                        to="#" 
                                        id="navbarDropdown" 
                                        role="button" 
                                        data-bs-toggle="dropdown" 
                                        aria-expanded="false"
                                    >
                                        Others
                                    </Link>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li><Link className="dropdown-item" to="/property-list">Property List</Link></li>
                                        <li><Link className="dropdown-item" to="/property-details">Property Details</Link></li>
                                    </ul>
                                </li>
                            </ul>
                            <div className="text-end ms-3">
                                <Link to="/login">
                                    <button type="button" className="btn btn-outline-light me-2">Login</button>
                                </Link>
                                {/* Uncomment the following line to enable the Sign-up button */}
                                {/* <Link to="/signup">
                                    <button type="button" className="btn btn-warning">Sign-up</button>
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
}

export default Header;
