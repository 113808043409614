import React, { useState } from 'react';
import PropertyDetails from './PropertyDetails';

function Homepage() {
    const [searchQuery, setSearchQuery] = useState('');
    const [filter, setFilter] = useState('all');

    const handleSearchChange = (e) => setSearchQuery(e.target.value);
    const handleFilterChange = (e) => setFilter(e.target.value);

    return (
        <>
            {/* Carousel */}
            <div id="myCarousel" className="carousel slide mt-5 mb-5" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="/assets/Images/Property/carousel1.jpg" className="d-block w-100 carousel-image" alt="Featured Property 1" />
                        <div className="carousel-caption d-flex justify-content-center align-items-center">
                            <div className="text-center text-light p-4 bg-dark bg-opacity-50 rounded">
                                <h1>Luxury Homes in Prime Locations</h1>
                                <p>Discover the best properties in the most sought-after locations. Your dream home awaits.</p>
                                <p><a className="btn btn-lg btn-primary" href="#">Explore Listings</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src="/assets/Images/Property/property-search.jpg" className="d-block w-100 carousel-image" alt="Featured Property 2" />
                        <div className="carousel-caption d-flex justify-content-center align-items-center">
                            <div className="text-center text-light p-4 bg-dark bg-opacity-50 rounded">
                                <h1>Affordable Housing Solutions</h1>
                                <p>Find affordable properties without compromising on quality. We have something for every budget.</p>
                                <p><a className="btn btn-lg btn-primary" href="#">See Offers</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src="/assets/Images/Property/premium-property.jpg" className="d-block w-100 carousel-image" alt="Featured Property 3" />
                        <div className="carousel-caption d-flex justify-content-center align-items-center">
                            <div className="text-center text-light p-4 bg-dark bg-opacity-50 rounded">
                                <h1>Exclusive Real Estate Deals</h1>
                                <p>Get access to exclusive deals and offers on premium properties. Act fast to secure your investment.</p>
                                <p><a className="btn btn-lg btn-primary" href="#">Browse Deals</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
            {/* Search and Filters */}
            <div className="container mb-5">
                <div className="row">
                    <div className="col-md-12 bg-dark text-white  pt-3 pb-3">
                        <h2 className="text-center mb-4">Find Your Dream Property</h2>
                        <form className="row g-3">
                            <div className="col-md-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search properties..."
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            </div>
                            <div className="col-md-3">
                                <select className="form-select" value={filter} onChange={handleFilterChange}>
                                    <option value="all">All Types</option>
                                    <option value="buy">Buy</option>
                                    <option value="rent">Rent</option>
                                    <option value="commercial">Commercial</option>
                                </select>
                            </div>
                            <div className="col-md-3">
                                <button type="submit" className="btn btn-primary w-100">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* PROPERTY DETAILS */}
            <PropertyDetails />
            {/* PROPERTY DETAILS ENDING  */}
            {/* Marketing Section */}
            <div className="container marketing">
                <div className="row justify-content-center">
                    <div className="col-md-12 heading-section text-center mb-5">
                        <span className="subheading">Our Services</span>
                        <h2 className="mb-2">Exclusive Real Estate Services</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="card">
                            <img src="/assets/Images/Property/services.jpg" className="card-img-top" alt="Service 1" />
                            <div className="card-body">
                                <h5 className="card-title">Property Buying</h5>
                                <p className="card-text">Expert guidance on purchasing properties, from finding the perfect match to closing the deal.</p>
                            </div>
                            <div className="card-body">
                                <button className='btn btn-primary'>Read More</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card">
                            <img src="/assets/Images/Property/services.jpg" className="card-img-top" alt="Service 2" />
                            <div className="card-body">
                                <h5 className="card-title">Property Selling</h5>
                                <p className="card-text">Professional services to help you sell your property quickly and at the best price.</p>
                            </div>
                            <div className="card-body">
                                <button className='btn btn-primary'>Read More</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card">
                            <img src="/assets/Images/Property/services.jpg" className="card-img-top" alt="Service 3" />
                            <div className="card-body">
                                <h5 className="card-title">Property Management</h5>
                                <p className="card-text">Comprehensive property management services to keep your investments in top shape.</p>
                            </div>
                            <div className="card-body">
                                <button className='btn btn-primary'>Read More</button>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="featurette-divider mb-5" />
                <div className="row justify-content-center">
                    <div className="col-md-12 heading-section text-center mb-5">
                        <span className="subheading">Top Locations</span>
                        <h2 className="mb-2">Discover the Best Real Estate Hotspots</h2>
                        <p className="lead">Explore our curated list of top locations for buying or renting your dream property. Find the perfect neighborhood that fits your lifestyle and preferences.</p>
                    </div>
                </div>

                <div className="row featurette mt-3">
                    <div className="col-md-7">
                        <h2 className="featurette-heading">Premium Properties in Top Locations. <span className="text-muted">Experience Luxury Living.</span></h2>
                        <p className="lead">Explore our selection of high-end properties situated in the most desirable locations. Find your dream home today.</p>
                    </div>
                    <div className="col-md-5">
                        <img src="/assets/Images/Property/premium-property.jpg" className="d-block w-100" alt="Premium Property" />
                    </div>
                </div>

                <hr className="featurette-divider" />

                <div className="row featurette">
                    <div className="col-md-7 order-md-2">
                        <h2 className="featurette-heading">Affordable Options for Every Budget. <span className="text-muted">Quality and Value.</span></h2>
                        <p className="lead">We offer a range of affordable housing options that provide excellent value for your money. Find the perfect fit for your budget.</p>
                    </div>
                    <div className="col-md-5 order-md-1">
                        <img src="/assets/Images/Property/property-search.jpg" className="d-block w-100" alt="Affordable Property" />
                    </div>
                </div>

                <hr className="featurette-divider" />

                <div className="row featurette">
                    <div className="col-md-7">
                        <h2 className="featurette-heading">Exclusive Deals and Offers. <span className="text-muted">Act Now!</span></h2>
                        <p className="lead">Check out our exclusive deals and special offers on properties. Don’t miss out on these limited-time opportunities!</p>
                    </div>
                    <div className="col-md-5">
                        <img src="/assets/Images/Property/premium-property.jpg" className="d-block w-100" alt="Exclusive Deals" />
                    </div>
                </div>

                <hr className="featurette-divider" />
            </div>
            {/* Custom Cards */}
            <div className="container px-4 py-5" id="custom-cards">
                <div className="row justify-content-center">
                    <div className="col-md-12 heading-section text-center mb-5">
                        <span className="subheading">Featured Listings</span>
                        <h2 className="mb-2">Find Properties in Your Area</h2>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
                    <div className="col">
                        <div className="card card-cover h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg" style={{ backgroundImage: 'url(/assets/Images/Property/property-search.jpg)' }}>
                            <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                                <h2 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Modern Downtown Apartment</h2>
                                <ul className="d-flex list-unstyled mt-auto">
                                    <li className="me-auto">
                                        <img src="https://github.com/twbs.png" alt="Bootstrap" width="32" height="32" className="rounded-circle border border-white" />
                                    </li>
                                    <li className="d-flex align-items-center me-3">
                                        <svg className="bi me-2" width="1em" height="1em"></svg>
                                        <small>3 Beds</small>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <svg className="bi me-2" width="1em" height="1em"></svg>
                                        <small>$1,200/month</small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card card-cover h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg" style={{ backgroundImage: 'url(/assets/Images/Property/property-search.jpg)' }}>
                            <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                                <h2 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Charming Family Home</h2>
                                <ul className="d-flex list-unstyled mt-auto">
                                    <li className="me-auto">
                                        <img src="https://github.com/twbs.png" alt="Bootstrap" width="32" height="32" className="rounded-circle border border-white" />
                                    </li>
                                    <li className="d-flex align-items-center me-3">
                                        <svg className="bi me-2" width="1em" height="1em"></svg>
                                        <small>4 Beds</small>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <svg className="bi me-2" width="1em" height="1em"></svg>
                                        <small>$2,500/month</small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card card-cover h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg" style={{ backgroundImage: 'url(/assets/Images/Property/property-search.jpg)' }}>
                            <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                                <h2 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">Spacious Suburban House</h2>
                                <ul className="d-flex list-unstyled mt-auto">
                                    <li className="me-auto">
                                        <img src="https://github.com/twbs.png" alt="Bootstrap" width="32" height="32" className="rounded-circle border border-white" />
                                    </li>
                                    <li className="d-flex align-items-center me-3">
                                        <svg className="bi me-2" width="1em" height="1em"></svg>
                                        <small>5 Beds</small>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <svg className="bi me-2" width="1em" height="1em"></svg>
                                        <small>$3,000/month</small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Homepage;
