import React from 'react';


const PropertyDetails = () => {
    return (
        <div className="container marketing mb-5">
            {/* Heading Section */}
            <div className="row justify-content-center">
                <div className="col-md-12 heading-section text-center mb-5">
                    <span className="subheading">Property</span>
                    <h2 className="mb-2">Property Details</h2>
                </div>
            </div>

            {/* Services Section */}
            <div className="row">
                {services.map((service, index) => (
                    <div className="col-lg-4 col-md-6 mb-4" key={index}>
                        <div className="card shadow-sm border-light">
                            <img src={service.image} className="card-img-top" alt={service.title} />
                            <div className="card-body">
                                <h5 className="card-title">{service.title}</h5>
                                <p className="card-text">{service.description}</p>
                                <table className="table table-striped table-hover">
                                    <tbody>
                                        <tr>
                                            <th><strong>Area:</strong></th>
                                            <td>{service.area} sq ft</td>
                                        </tr>
                                        <tr>
                                            <th><strong>Price:</strong></th>
                                            <td>₹{service.price}</td>
                                        </tr>
                                        <tr>
                                            <th><strong>Location:</strong></th>
                                            <td>{service.location}</td>
                                        </tr>
                                        <tr>
                                            <th><strong>Type:</strong></th>
                                            <td>{service.type}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer text-center">
                                <button className='btn btn-primary'>Read More</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

// Sample data for services with additional fields
const services = [
    {
        title: 'Golden Urban House For Sale',
        description: 'Expert guidance on purchasing properties, from finding the perfect match to closing the deal.',
        image: '/assets/Images/Property/property-search.jpg',
        area: '1500',
        price: '45,00,000',
        location: 'Downtown City',
        type: 'Residential'
    },
    {
        title: 'Elegant Suburban Villa For Sale',
        description: 'Professional services to help you sell your property quickly and at the best price.',
        image: '/assets/Images/Property/property-search.jpg',
        area: '1200',
        price: '35,00,000',
        location: 'Uptown Area',
        type: 'Commercial'
    },
    {
        title: 'Modern City Apartment For Sale',
        description: 'Comprehensive property management services to keep your investments in top shape.',
        image: '/assets/Images/Property/property-search.jpg',
        area: '1800',
        price: '60,00,000',
        location: 'Suburban District',
        type: 'Mixed-use'
    }
];

export default PropertyDetails;
