import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './Components/Layout';
import Homepage from './Components/Homepage';
import Login from './Components/Login';
import PropertyDetails from './Components/PropertyDetails';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route peth='/' element={<Layout />}>
          <Route index element={<Homepage />} />
          <Route path='/property-details' element={<PropertyDetails />} />
        </Route>
        <Route path='/login' element={<Login />}/>
      </Routes>
    </BrowserRouter >
  </>
  );
}

export default App;
