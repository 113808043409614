import React from 'react';

function Footer() {
    return (
        <footer className="container-fluid bg-dark text-white pt-5 pb-2">
            <div className="row ">
                <div className="col-md-4 mb-md-0  text-center">
                    <img src="/assets/Images/Property/logo.png" alt="Company Logo" className="img-fluid" style={{ width: '300px' }} />
                </div>
                <div className="col-md-2 mb-md-0  text-center">
                    <h5>Quick Links</h5>
                    <ul className="list-unstyled">
                        <li><a href="#" className="text-white text-decoration-none">Home</a></li>
                        <li><a href="#" className="text-white text-decoration-none">About Us</a></li>
                        <li><a href="#" className="text-white text-decoration-none">Services</a></li>
                        <li><a href="#" className="text-white text-decoration-none">Contact</a></li>
                        <li><a href="#" className="text-white text-decoration-none">Privacy Policy</a></li>
                    </ul>
                </div>
                <div className="col-md-2 mb-md-0  text-center">
                    <h5>Quick Links</h5>
                    <ul className="list-unstyled">
                        <li><a href="#" className="text-white text-decoration-none">Home</a></li>
                        <li><a href="#" className="text-white text-decoration-none">About Us</a></li>
                        <li><a href="#" className="text-white text-decoration-none">Services</a></li>
                        <li><a href="#" className="text-white text-decoration-none">Contact</a></li>
                        <li><a href="#" className="text-white text-decoration-none">Privacy Policy</a></li>
                    </ul>
                </div>
                <div className="col-md-4 mb-md-0">
                    <h5>Follow Us</h5>
                    <ul className="list-unstyled d-flex">
                        <li className="me-3">
                            <a href="#" aria-label="Facebook" className="text-white text-decoration-none">
                                <i className="bi bi-facebook"></i>
                            </a>
                        </li>
                        <li className="me-3">
                            <a href="#" aria-label="Twitter" className="text-white text-decoration-none">
                                <i className="bi bi-twitter"></i>
                            </a>
                        </li>
                        <li className="me-3">
                            <a href="#" aria-label="Instagram" className="text-white text-decoration-none">
                                <i className="bi bi-instagram"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#" aria-label="LinkedIn" className="text-white text-decoration-none">
                                <i className="bi bi-linkedin"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col  pt-5 text-center">
                    <p>&copy; 2024 Bechoproperty. All rights reserved.</p>
                </div>
                <div className="col-sm-2 text-end">
                    <a href="#" aria-label="Scroll to top" className="text-white text-decoration-none scroll-to-top">
                        <i className="bi bi-arrow-up-circle-fill" style={{ fontSize: '50px' }}></i>
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
